/* global config */

import React, { useState, useEffect } from "react";
import "../login/Login.css";
import api from "../../services/api";
import "./VisitorConference.css";
require("dotenv/config");

export function VisitorConference({ match, history }) {
  let v4h_ss_domain = `${config.connection.conf_url}/session-service`;

  let coreapi = window.coreapi;
  let schema = window.schema;
  let loggedIn = false;
  let node = {};
  let jApi = {};
  let sessionId = "";
  let client = new coreapi.Client(v4h_ss_domain);
  let EndedListener;

  const token = history.location.state.tokenSession;
  const uuidSession = history.location.state.uuidSession;
  const settingsCam = history.location.state.camStatus;
  const settingsMic = history.location.state.microphoneStatus;
  const userName = history.location.state.userName;

  console.log("token: ", token);
  console.log("uuidSession: ", uuidSession);

  useEffect(() => {
    start();
    async function start() {
      const { data } = await api.get(`/videosession/${token}`);

      try {
        await loginWithToken(data.access);

        startConference(
          uuidSession,
          document.querySelector("#meet"),
          "100%",
          "100%"
        );

        //_openJitsi(uuidSession, token, document.querySelector('#meet'), "100%", "100%");
      } catch (err) {
        console.log("Não conseguiu obter o token {token error!}");
        console.error(err.data);
      }
    }

    function loginWithToken(access_token) {
      return new Promise(function (resolve, reject) {
        let action = ["api", "token", "verify", "create"];
        let params = { token: access_token };
        client
          .action(schema, action, params)
          .then(function (result) {
            let auth = new coreapi.auth.TokenAuthentication({
              token: access_token,
            });
            client = new coreapi.Client({ auth: auth });
            loggedIn = true;
            resolve(token);
          })
          .catch(function (error) {
            console.error(error);
            loggedIn = false;
            reject(error);
          });
      });
    }

    function joinConference(sessionId, parentNode, width, height) {
      console.log("estou entrando na sala de uuid: ", sessionId);
      return new Promise(function (resolve, reject) {
        node = parentNode;
        let action = ["conferences", "update"];
        let params = { sessionId: sessionId, conf_state: 2 };
        client
          .action(schema, action, params)
          .then(function (result) {
            sessionId = result["sessionId"];
            console.log(
              "V4H-rest-cli-api :: joining conf on room " + sessionId
            );
            _openJitsi(
              sessionId,
              result["conf_auth"],
              parentNode,
              width,
              height
            );
            resolve(sessionId);
          })
          .catch(function (error) {
            console.error(error);
            reject(error);
          });
      });
    }
  }, [token, uuidSession]);

  function startConference(sessionId, parentNode, width, height) {
    return new Promise(function (resolve, reject) {
      node = parentNode;
      let action = ["conferences", "update"];
      let params = { sessionId: sessionId, conf_state: 1 };
      client
        .action(schema, action, params)
        .then(function (result) {
          sessionId = result["sessionId"];
          console.log("testteeee ##", result);
          console.log(
            "V4H-rest-cli-api 1:: starting conf on room " + sessionId
          );
          _openJitsi(sessionId, result["conf_auth"], parentNode, width, height);
          resolve(sessionId);
        })
        .catch(function (error) {
          console.error(error);
          reject(error);
        });
    });
  }

  function _openJitsi(sessionId, jwt, parentNode, width, height) {
    width = width || 640;
    height = height || 480;
    const domain = config.connection.conf_url;
    const options = {
      roomName: sessionId,
      width: width,
      height: height,
      noSsl: false,
      parentNode: parentNode,
      interfaceConfigOverwrite: {
        TOOLBAR_BUTTONS: [
          "microphone",
          "camera",
          "closedcaptions",
          "desktop",
          "fullscreen",
          "fodeviceselection",
          "hangup",
          "profile",
          "chat",
          "sharedvideo",
          "settings",
          "raisehand",
          "videoquality",
          "filmstrip",
          "feedback",
          "stats",
          "shortcuts",
          "tileview",
          "help",
          "mute-everyone",
        ],
      },
      jwt: jwt,
    };

    jApi = new window.JitsiMeetExternalAPI(domain, options);

    /* audio and camera settings of conference */
    jApi.executeCommand("displayName", `${userName}`);

    if (!settingsCam) {
      jApi.executeCommand("toggleVideo");
    }
    if (!settingsMic) {
      jApi.executeCommand("toggleAudio");
    }

    jApi.addEventListener("readyToClose", () => conferenceEnded());
  }

  function _endedConference() {
    let action = ["conferences", "update"];
    let params = { sessionId: sessionId, conf_state: 3 };
    client
      .action(schema, action, params)
      .then(function (result) {
        console.log(
          "V4H-rest-cli-api :: updated conf " +
            result["sessionId"] +
            " conf_state to " +
            result["conf_state"]
        );
        conferenceEnded();
      })
      .catch(function () {
        conferenceEnded();
      });
    conferenceEnded();
  }

  function conferenceEnded() {
    document.getElementById("meet").innerHTML = "";
    window.location.replace(config.connection.backend_url.replace("/backend",""));
  }

  return (
    <div className="visitants-container">
      <div id="meet"></div>
    </div>
  );
}
