import React from "react";
import { Menu, Icon, Avatar } from "antd";
import { AvatarUser } from "../menuAccountConfig";
import "./MenuItens.css";
import { isAuthenticated } from "../../auth/authSession";
import { AuthAPI } from "../../services";

import logo from "../../assets/v4h/login-logo.png";

export default class MenuItens extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: this.props.history.location.pathname,
      mode: this.props.mode,
      theme: this.props.menu_theme,
      showModal: false,
      collapsedMenu: this.props.collapsedMenu,
    };
  }

  componentDidMount() {
    console.log("teste-menu", this.props.menu_theme);
  }

  handleClick = async (e) => {
    if (e.key == "setting") {
      if (this.state.showModal) {
        this.setState({ showModal: false });
        console.log("state false");
      } else {
        this.setState({ showModal: true });
        console.log("state true");
      }
      return null;
    }

    localStorage.setItem(
      "isAuthenticated",
      await AuthAPI.tokenValidation(localStorage.getItem("v4h_token"))
    );

    this.setState({
      current: e.key,
    });

    this.props.history.push(`${e.key}`);
  };

  render() {
    return (
      <div>
        <Menu
          defaultSelectedKeys={["1"]}
          mode={this.state.mode}
          theme={'dark'}
          onClick={this.handleClick}
          selectedKeys={[this.state.current]}
        >
          <Menu.Item disabled="true" style={{width: '100px'}}>
            <button onClick={this.state.collapsedMenu}>
              <img className="v4h-logo-menu" src={logo} alt="v4h" />
            </button>
            <div id="menuItenAvatar">
              <AvatarUser history={this.props.history} mode="bottomLeft" />
            </div>
          </Menu.Item>
          <Menu.Item key="/ScheduleAppointment" style={{borderBottom:'none'}}>
            <Icon type="calendar" />
            <span>Agenda</span>
          </Menu.Item>
          <Menu.Item key="/quickConference" style={{borderBottom:'none'}}>
            <Icon type="video-camera" />
            <span>Sessão Rápida</span>
          </Menu.Item>
          <Menu.Item key="/session" style={{borderBottom:'none'}}>
            <Icon type="play-circle" />
            <span>Sessões Gravadas</span>
          </Menu.Item>
        </Menu>
      </div>
    );
  }
}
