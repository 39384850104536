import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import { Login } from "./pages/login";
import { Session } from "./pages/session";
import { Register } from "./pages/register";
import {
  ScheduleAppointment,
  ScheduledSessionConference,
  testProxy,
} from "./pages/scheduleAppointment";

import { ResultSucess } from "./pages/resultSucess/ResultSucess";
import { ForgetPassword } from "./pages/forgetPassword/ForgetPassword";

import { VisitorConference } from "./pages/visitorConference";
import { MyAccount } from "./pages/myAccount";
import { Signin, ChangePassword } from "./pages/common";
import { QuickConference, NewQuickConference } from "./pages/quickConference";
import { ConferenceSettings } from "./pages/conferenceSettings";
import { isAuthenticated } from "./auth/authSession";
import Mobile from "./pages/mobile";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() == "true" ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: `/login${rest.path}`,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        {/**********/}
        {/* public */}
        {/**********/}

        {/* Login */}
        <Route path="/" exact component={Login} />
        <Route path="/cadastro" component={Login} />
        <Route path="/login/:page" component={Login} />
        <Route path="/forgotPassword" component={ForgetPassword} />

        {/* Register */}
        <Route path="/register/:active" component={Register} />

        {/* Video conference */}
        <Route
          path="/visitantes/:typeConference/:uuidSession/:token"
          component={ConferenceSettings}
        />
        <Route
          path="/videoConference/visitantes"
          component={VisitorConference}
        />

        {/* Mobile */}
        <Route path="/mobile" component={Mobile} />

        <Route path="/resultSucess" component={ResultSucess} />

        <Route
          path="/changepassword/:account/:token"
          component={ChangePassword}
        />

        {/***********/}
        {/* private */}
        {/***********/}

        {/* application */}
        <PrivateRoute path="/session" component={Session} />
        <PrivateRoute
          path="/ScheduleAppointment"
          component={ScheduleAppointment}
        />

        {/* User Settings */}
        <PrivateRoute path="/MyAccount" component={MyAccount} />
        <PrivateRoute path="/signin" component={Signin} />
        <PrivateRoute
          path="/changepassword/:token"
          component={ChangePassword}
        />

        {/* video conference */}
        <PrivateRoute path="/quickConference" component={NewQuickConference} />
        <PrivateRoute
          path="/quickConferenceSetting"
          component={ConferenceSettings}
        />
        <PrivateRoute
          path="/scheduledSession/:uuidSession"
          component={ConferenceSettings}
        />
        <PrivateRoute
          path="/videoConference/quickConference"
          component={QuickConference}
        />
        <PrivateRoute
          path="/videoConference/scheduledSession"
          component={ScheduledSessionConference}
        />

        <Route path="/:default" component={Login} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
