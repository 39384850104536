import React from 'react';
import './ConferenceSettingsBody.css'
import { VideocamOutlined, VideocamOffOutlined, MicNoneOutlined, MicOffOutlined } from '@material-ui/icons';
import { ConfigModal } from '../menuAccountConfig';
import $ from 'jquery';

export class ConferenceSettingsBody extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            camStatus: 'true',
            microphoneStatus: 'true',
            visible: false,
            userName: '',
            modeConference: this.props.history.location.pathname.split('/')[1],
            confName: ' ',
            typeConference: '',
            device: '',
        }

        /** Params **/
        this.token = this.props.match.params.token;
        this.uuidSession = this.props.match.params.uuidSession;

        console.log("Dados da sessão - token: ", this.token);
        console.log("Dados da sessão - uuidSession: ", this.uuidSession);
    }


    componentDidMount() {

        if (this.state.modeConference == 'visitantes') {
            // check if it is a mobile device.
            // if (this._isMobile()) {
            //     return this.props.history.push('/mobile', {
            //         token: this.token,
            //         sessionId: this.uuidSession
            //     });
            // }
            this.setState({ visible: true });
        }

        this.loadCamera();

        try {
            this.setState({ 'confName': this.props.history.location.state.confName });
        } catch {
            this.setState({ 'confName': '' });
        }

        try {
            this.setState({ 'typeConference': this.props.history.location.state.typeConference });
        } catch {
            this.setState({ 'typeConference': this.props.match.params.typeConference });
        }

    }

    /**
     * Check which device is accessing the page.
     */
    _isMobile = () => {
        var ua = navigator.userAgent.toLowerCase();
        var uMobile = ['iphone', 'ipod', 'iPad', 'Opera Mini', 'BlackBerry', 'windows phone', 'android', 'iemobile 8'];

        // go through all the items, making sure it is mobile.
        for (var i = 0; i <= uMobile.length; i++) {
            if (ua.indexOf(uMobile[i]) != -1) {
                return true;
            }
        }

        return false;
    }

    /*******************/
    /** CONFIG CAMERA **/
    /*******************/
    loadCamera = () => {
        this.setState({ camStatus: true });
        localStorage.setItem('v4h_camera', true);
        setTimeout(() => {
            //Captura elemento de vídeo
            var video = document.querySelector("#webCamera");

            //Verifica se o navegador pode capturar mídia
            if (navigator.mediaDevices.getUserMedia) {
                navigator.mediaDevices.getUserMedia({ audio: false, video: { facingMode: 'user' } })
                    .then(function (stream) {
                        window.localStream = stream;

                        //Definir o elemento vídeo a carregar o capturado pela webcam
                        video.srcObject = stream;

                        //As opções abaixo são necessárias para o funcionamento correto no iOS
                        video.setAttribute('autoplay', '');
                        video.setAttribute('muted', '');
                        video.setAttribute('playsinline', '');
                    })
                    .catch(function (error) {
                        //this.setState({ camStatus: false});
                    });
            }
        }, 300);
    }

    stopCamera = () => {
        this.setState({ camStatus: false });
        localStorage.setItem('v4h_camera', false);
        try {
            // stop both video and audio
            window.localStream.getTracks().forEach((track) => {
                track.stop();
            });
        } catch {
            return;
        }
    }
    /*******************/
    /** CONFIG CAMERA **/
    /*******************/


    /******************/
    /** CONFIG MODEL **/
    /******************/
    handleOk = (data) => {
        this.setState({ visible: false, userName: data.firstName + ' ' + data.lastName });
        setTimeout(() => {
            if (this.state.typeConference == 'class' && this.state.modeConference == 'visitantes') {
                this.setState({ camStatus: 'false', microphoneStatus: 'false' });
                this.startConference();
            }
        }, 200);
    };

    handleCancel = () => {
        if (this.state.title == 'Email') {
            $('#inputConfigModal_email').removeClass('error-form');
            $('.error-msg').removeClass('error-msg-visible');
        }
        this.setState({ visible: false });
    };
    /******************/
    /** CONFIG MODEL **/
    /******************/

    startConference = () => {
        console.log("Dados da sessão: ", this.token, this.uuidSession);
        this.stopCamera();
        if (this.state.modeConference === 'visitantes') {
            this.props.history.push('/videoConference/visitantes/', {
                uuidSession: this.uuidSession,
                tokenSession: this.token,
                userName: this.state.userName,
                camStatus: this.state.camStatus,
                microphoneStatus: this.state.microphoneStatus,
            });
        }
        else if (this.state.modeConference === 'scheduledSession') {
            this.props.history.push('/videoConference/scheduledSession', {
                confName: this.props.history.location.state.confName,
                uuidSession: this.uuidSession,
                camStatus: this.state.camStatus,
                microphoneStatus: this.state.microphoneStatus,
                typeConference: this.state.typeConference,
            });
        }
        else {
            console.log(this.state.modeConference);
            this.props.history.push('/videoConference/quickConference', {
                confName: this.props.history.location.state.confName,
                camStatus: this.state.camStatus,
                microphoneStatus: this.state.microphoneStatus,
                typeConference: this.state.typeConference,
            });
        }
    }

    isForVisitant = () => {
      if (this.state.modeConference === 'visitantes') {
        return (<div className="container-name">
        <label className="form-label">Seu nome</label>
        <input
          id="inputConfigModal_firstName"
          autoFocus
          type="text"
          value={this.state.userName}
          onChange={(event) => {
            this.setState({ userName: event.target.value });
          }}
        />
        </div>)
      }
    }

    goBack = () => {
      this.props.history.goBack();
    }


    render() {
        // if (this.state.typeConference === 'class' && this.state.modeConference === 'visitantes') {
        //   return (
        //     <div className="conferenceSettings-body">
        //         <div className="nameConference">
        //             <span>{this.state.confName}</span>
        //         </div>
        //         <div className="conferenceSettings-cam">
        //             {this.state.camStatus ? <video autoplay="true" id="webCamera"></video> : <div id="no-webCamera">Câmera Desativada.</div>}
        //         </div>
        //         <div className="buttons">
        //             <div className="btn-div">
        //                 {this.state.camStatus ? <VideocamOutlined className="btn" onClick={() => this.stopCamera()} /> : <VideocamOffOutlined className="btn" onClick={() => this.loadCamera()} />}
        //             </div>
        //             <div className="btn-div">
        //                 {this.state.microphoneStatus ? <MicNoneOutlined className="btn" onClick={() => this.setState({ microphoneStatus: false })} /> : <MicOffOutlined className="btn" onClick={() => this.setState({ microphoneStatus: true })} />}
        //             </div>
        //         </div>

        //         <p className="participants" >Procurando outras pessoas na reunião...</p>

        //         {this.isForVisitant()}

        //         <button className="btn_startConf" onClick={this.startConference}>Entrar na reunião</button>
        //     </div>
        // );
        // }

        return (
            <div className="conferenceSettings-body">
                <div className="nameConference">
                    <span>{this.state.confName}</span>
                </div>
                <div className="conferenceSettings-cam">
                    {this.state.camStatus ? <video autoplay="true" id="webCamera"></video> : <div id="no-webCamera">Câmera Desativada.</div>}
                </div>
                <div className="buttons">
                    <div className="btn-div">
                        {this.state.camStatus ? <VideocamOutlined className="btn" onClick={() => this.stopCamera()} /> : <VideocamOffOutlined className="btn" onClick={() => this.loadCamera()} />}
                    </div>
                    <div className="btn-div">
                        {this.state.microphoneStatus ? <MicNoneOutlined className="btn" onClick={() => this.setState({ microphoneStatus: false })} /> : <MicOffOutlined className="btn" onClick={() => this.setState({ microphoneStatus: true })} />}
                    </div>
                </div>

                <p className="participants" >Procurando outras pessoas na reunião...</p>

                {this.isForVisitant()}

                <div className="actionButtons">
                  <button className="btn_back" onClick={this.goBack}>Voltar</button>
                  <button className="btn_startConf" onClick={this.startConference}>Entrar na reunião</button>
                </div>
            </div>
        );
    }

}
