import api from "./api";
import { message } from "antd";
import moment from "moment";

class ConferenceAPI {
  /**
   * Get conferences scheduled with or by the user.
   *
   * @param {string} token
   *
   * @returns {Object}
   */
  getScheduledConferences = async (token) => {
    try {
      let response = await api.get("/videosession", {
        headers: { Authorization: "Bearer " + token },
      });

      console.log(response.data);

      response = response.data;
      let hostName = {
        name: "",
        uuid: "",
        email: "",
      };
      for (var x in response) {
        var users = [];
        for (var y in response[x].users) {
          if (response[x].host_id === response[x].users[y].uuid) {
            hostName = {
              name:
                response[x].users[y].first_name +
                " " +
                response[x].users[y].last_name,
              uuid: response[x].users[y].uuid,
              email: response[x].users[y].email,
            };
          } else {
            const user = {
              name:
                response[x].users[y].first_name +
                " " +
                response[x].users[y].last_name,
              uuid: response[x].users[y].uuid,
              email: response[x].users[y].email,
            };
            users.push(user);
          }
        }

        let schedule = response[x].schedule_date.split("Z")[0];
        response[x].users = users;
        response[x].host_id = hostName;
        response[x].schedule_date = moment(schedule);
        response[x].start_time = moment(
          schedule.split("T")[0] + "T" + response[x].start_time
        );
        response[x].finish_time = moment(
          schedule.split("T")[0] + "T" + response[x].finish_time
        );
      }

      return response;
    } catch (error) {
      if (error.response.status === "401") {
        localStorage.setItem("isAuthenticated", false);
        window.location.reload(true);
      }

      message.error("Não foi possível obter os dados do servidor.");
    }
  };

  /**
   * get recorded conferences.
   *
   * @param {string} token
   *
   * @returns {Object}
   */
  getRecordedConferences = async (token) => {
    try {
      let response = await api.get(
        "/videosessionRecorded",
        { headers: { Authorization: "Bearer " + token } },
        {
          userId: localStorage.getItem("v4h_uuid"),
        }
      );
      console.log("sessões gravados: ", response.data);
      return response.data;
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.setItem("isAuthenticated", false);
        window.location.reload(true);
      }

      message.error("Não foi possível obter os dados do servidor.");
    }
  };

  /**
   * This method updates the end time of the conference.
   *
   * @param {String} sessionId
   * @param {String} token
   * @param {String} finish_time
   *
   * @returns {Object}
   */
  updateConference = async (sessionId, data) => {
    const token = localStorage.getItem("v4h_token");
    try {
      let response = await api.put(
        `/videosession/${sessionId}`, data,
        {
          headers: { Authorization: "Bearer " + token },
        }
      );

      return response.data;
    } catch (error) {
      console.error('updateConference');
    }
  };

  readSession = async (sessionId, token) => {
    try {
      let response = await api.get(
        `/videosession/conferences/read/${sessionId}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.log("error ao ler sessão: ", error.response.data);
    }
  };

  deleteSchedule = async (uuid, resolve, reject) => {
    try {
      const response = await api.delete(`/videosession/${uuid}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("v4h_token"),
        },
      });
      resolve();
      message.success("Evento removido com sucesso!");
      setTimeout(() => window.location.reload(false), 1000);
    } catch (err) {
      reject();
      console.log("deu error: ", err.response.status);
      message.error("Falhar ao remover o evento.");
    }
  };

  updateSchedule = async (uuid, data) => {
    try {
      const response = await api.put(
        `/videosession/${uuid}`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("v4h_token"),
          },
        }
      );

      message.success("Evento atualizado com sucesso!");
    } catch (err) {
      message.error("Falhar ao atualizar o evento.");
    }
  };
}

export default new ConferenceAPI();
