import React from "react";
import moment from 'moment';
import {
  notification,
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Empty,
  Input,
  Select,
  DatePicker,
  TimePicker,
  Spin,
  Tooltip,
} from "antd";
import "./AddButton.css";
import api from "../../services/api";

const { RangePicker } = DatePicker;
const { Option } = Select;

export class AddButton extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      title: "",
      scheduleDate: new Date(),
      collaborators: [],
      startTime: "",
      finishTime: "",
      description: "",
      loading: "",
      typeConference: "class",
      readyToSend: false,
      startTimeDate: null,
      finishTimeDate: null,
      startValidator: "",
      finishValidator: "",
    };

    this.children = [];
    this.invalidInputs = [];
    this.collaboratorsUuid = [];
    this.collaboratorsEmail = [];
  }

  async componentDidMount() {
    const response = await api.get("/companies/users", {
      headers: { Authorization: "Bearer " + localStorage.getItem("v4h_token") },
    });

    this.props.form.setFieldsValue({
      date: moment(this.state.scheduleDate)
    });

    console.log(response.data);

    for (var x in response.data) {
      if (response.data[x].uuid === localStorage.getItem("v4h_uuid")) {
        continue;
      }
      this.children.push(
        <Option key={[response.data[x].uuid, response.data[x].email]}>
          {response.data[x].email}
        </Option>
      );
    }
  }

  showDrawer = () => {
    this.setState(
      {
        visible: true,
      },
      () => {
        setTimeout(() => {
          this.testInput && this.testInput.focus();
        }, 1);
      }
    );
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
    this.props.form.resetFields();
  };

  handleTitle = (e) => {
    console.log(e.target.value);
    this.setState({ title: e.target.value });
    this.validateFields();
  };

  validateFields = () => {
    console.log(this.invalidInputs.length);
    return this.state.title.length > 1 && this.state.scheduleDate && this.state.collaborators.length > 0 && this.state.startTimeDate && this.state.finishValidator !== "error" && this.invalidInputs.length === 0;
  }

  handleTypeConference = (value) => {
    this.setState({
      typeConference: value,
    });
  };

  handleCollaborators = (value) => {
    this.setState({ collaborators: value });
    // setTimeout(() => console.log(this.state.collaborators), 1000);
  };

  handleDate = (e) => {
    if (!e) {
      this.setState({ scheduleDate: '' });
      return;
    }
    
    const year = e._d.getFullYear();
    const month = this.formatHour(e._d.getMonth() + 1);
    const day = this.formatHour(e._d.getDate());
    const hours = String(e._d).substring(16, 24);

    this.setState({ scheduleDate: year + "-" + month + "-" + day + " " + hours });
  };

  formatHour = (value) => {
    if (String(value).length == 1) {
      return "0" + value;
    }
    return value;
  };

  handleStartTime = (e) => {

    if (!e) {
      this.setState({ startTimeDate: null });
      return;
    }

    if (this.state.finishTimeDate && (e._d.getTime() > this.state.finishTimeDate.getTime())) {
      this.setState({ startTimeDate: e._d, finishValidator: "error" });
    } else {
      const temp = moment(e._d);
      this.props.form.setFieldsValue({ timeend: temp.add(1, 'hours') });
      console.log(String(e._d).substring(16, 21));
      this.setState({ 
        startTimeDate: e._d, 
        startTime: String(e._d).substring(16, 21), 
        finishTime: temp.format('HH:mm')
      });
    }

  };

  handleFinishTime = (e) => {

    if (!e) {
      this.setState({ finishTime: "", finishValidator: "error" });
      return;
    }
    
    console.log(e._d);
    console.log(this.state.startTimeDate);

    if (e._d.getTime() < this.state.startTimeDate.getTime()) {
      this.setState({ finishValidator: "error" });
    } else {
      this.setState({ finishValidator: "", finishTimeDate: e._d });
      this.setState({ finishTime: String(e._d).substring(16, 21) });
    }

  };

  handleDescription = (e) => {
    this.setState({
      description: e.target.value,
    });
  };

  adjustParticipantData = async () => {
    //adjustment in participants.
    for (var i = 0; i < this.state.collaborators.length; i++) {

      console.log(i);
      console.log(this.state.collaborators);

      const uuid_email = this.state.collaborators[i].split(",");

      if (uuid_email.length === 2) {
        this.collaboratorsUuid.push(uuid_email[0]);
      } else {
        this.collaboratorsEmail.push(uuid_email[0]);
      }

    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    await this.adjustParticipantData();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
      }
      console.log(values);
      if (values.timestart.isAfter(values["timeend"])) {
        this.props.form.setFields({
          timeend: {
            value: values.timeend,
            errors: [new Error("Hora de fim menor")],
          },
        });
      }
    });

    // const dataToSend = {
    //   title: this.state.title,
    //   description: this.state.description,
    //   scheduleDate: this.state.scheduleDate,
    //   startTime: this.state.startTime,
    //   finishTime: this.state.finishTime,
    //   collaborators: this.collaboratorsUuid,
    //   collaboratorsEmail: this.collaboratorsEmail,
    //   type: this.state.typeConference,
    // };

    // return console.log("dataToSend: ", dataToSend);

    try {
      const response = await api.post(
        "/videosession",
        {
          title: this.state.title,
          description: this.state.description,
          scheduleDate: this.state.scheduleDate,
          startTime: this.state.startTime,
          finishTime: this.state.finishTime,
          collaborators: this.collaboratorsUuid,
          collaboratorsEmail: this.collaboratorsEmail,
          type: this.state.typeConference,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("v4h_token"),
          },
        }
      );
      console.log(response);
      this.onClose();
      this.setState({ loading: false });
      this.openNotificationWithIcon(
        "success",
        "Sucesso.",
        "Agendamendo realizado com Sucesso."
      );
      setTimeout(() => {
        window.location.reload(false);
      }, 500);
    } catch (error) {
      this.setState({ loading: false });
      this.openNotificationWithIcon(
        "error",
        "Falha ao agendar videoconferência.",
        "Houve um problema ao processar o agendamento, tente novamente."
      );
    }
  };

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description,
    });
  };

  disabledDate = (current) => {
    // Can not select days before today and today
    return current && current <= moment().subtract(1, 'days');
  }

  emailValidator = (rule, values, callback) => {
    // this.onValuesChange();
    const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    this.invalidInputs = [];
    console.log(this.invalidInputs);

    values.map((value) => {
      let arr = value.split(',');

      console.log('ARR is ARRAY: ', Array.isArray(arr));
      console.log('REGEX: ', emailRegex.test(arr[1]));
      console.log('ARR: ', arr);

      console.log(arr.length === 2 && !emailRegex.test(arr[1]));
      if(arr.length === 2 && !emailRegex.test(arr[1])) {
        console.log('User: ', arr[1])
        this.invalidInputs.push(arr[1]);
      }

      console.log(arr.length === 1 && !emailRegex.test(value));
      if (arr.length === 1 && !emailRegex.test(value)) {
        console.log('Standalone: ', value)
        this.invalidInputs.push(value);
      }

    });

    console.log('INVALID INPUTS');
    console.log(this.invalidInputs);

    if(this.invalidInputs.length === 0){
        callback()
    } else {
      callback('Existem e-mails não válidos');
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="addDropdown">
        <Tooltip placement="top" title={"Criar sessão"}>
          <button className="addButton" onClick={this.showDrawer}>
            <span>+ Criar Reunião</span>
          </button>
        </Tooltip>
        <Drawer
          title="Adicionar uma nova sessão"
          width={720}
          onClose={this.onClose}
          maskClosable={false}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Spin spinning={this.state.loading}>
            <Form id="form1" layout="vertical">
              <Row gutter={16}>
                <Col span={17}>
                  <Form.Item label="Título">
                    {getFieldDecorator("title", {
                      rules: [
                        {
                          required: true,
                          message: "Por favor, adicione o título",
                        },
                      ],
                    })(
                      <Input className="teste"
                        ref={(input) => {
                          this.testInput = input;
                        }}
                        placeholder="Adicione o título"
                        onChange={this.handleTitle}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item label="Tipo da Sessão">
                    <Select
                      defaultValue="class"
                      onChange={this.handleTypeConference}
                    >
                      <Option value="conference">Conferência</Option>
                      <Option value="class">Aula</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Participantes">
                    {getFieldDecorator("participants", {
                      rules: [
                        {
                          required: true,
                          message: "Por favor, selecione os Participantes",
                        },
                        {
                          validator: this.emailValidator
                        }
                        // {
                        //   required: true,
                        //   type: "regexp",
                        //   pattern: new RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i),
                        //   message: "Por favor, digite um email válido",
                        // }
                      ],
                    })(
                      <Select
                        notFoundContent={<Empty description={"Sem Dados"} />}
                        mode="tags"
                        style={{ width: "100%" }}
                        placeholder="Selecione os Participantes"
                        onChange={this.handleCollaborators}
                      >
                        {this.children}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Data" name="data">
                    {getFieldDecorator("date", {
                      rules: [
                        {
                          required: true,
                          message: "Por favor, escolha a data",
                        },
                      ],
                    })(
                      <DatePicker
                        size={"default"}
                        disabledDate={this.disabledDate}
                        placeholder="Selecione a Data"
                        onChange={this.handleDate}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Hora">
                    {getFieldDecorator("timestart", {
                      rules: [
                        {
                          required: true,
                          message: "Por favor, escolha o horário de início",
                        },
                      ],
                    })(
                      <TimePicker
                        format={"HH:mm"}
                        placeholder="Início"
                        onChange={this.handleStartTime}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item validateStatus={this.state.finishValidator}>
                    {getFieldDecorator("timeend", {
                      rules: [
                        {
                          required: true,
                          message: "Por favor, escolha o horário de término",
                        },
                      ],
                    })(
                      <TimePicker
                        format={"HH:mm"}
                        style={{ marginTop: "28px" }}
                        placeholder="Fim"
                        onChange={this.handleFinishTime}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Descrição">
                    {getFieldDecorator(
                      "description",
                      {}
                    )(
                      <Input.TextArea
                        rows={4}
                        placeholder="Adicione uma descrição"
                        onChange={this.handleDescription}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>

            <div className="typeMsg">
              <h3>Observação</h3>
              {this.state.typeConference === "conference" ? (
                <p>
                  <strong>Conferência: </strong> neste modo os visitantes podem
                  configurar como eles querem entrar na conferência.
                </p>
              ) : (
                <p>
                  <strong>Aula: </strong> Os visitantes entram na conferência
                  com a imagem(câmera) e audio(microfone) desligados.
                </p>
              )}
            </div>
          </Spin>
          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#fff",
              textAlign: "right",
            }}
          >
            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
              Cancelar
            </Button>
            <Button onClick={this.handleSubmit} htmlType="submit" type="submit" disabled={!this.validateFields()}>
              Salvar
            </Button>
          </div>
        </Drawer>
      </div>
    );
  }
}
