import React, {useEffect, useState} from 'react';
import { message } from 'antd';
import $ from 'jquery';

import './NewQuickConference.css';
import { MenuV4h } from '../../compoments/menu';

export const NewQuickConference = ({ history, match }) => {

  const [confName, setConfName] = useState('');

  const handleCreateConference = () => {
    if(!validateInput()) return;
    history.push('/quickConferenceSetting', {
      confName: confName
    });
  }

  const validateInput = () => {
    if(confName == '') {
      $('.confname-input').addClass('inputError');
      message.error('Você não pode criar uma conferência rápida sem nome');
      return false;
    }
    return true;
  }

  const keyUpHandler = (e) => {
    if (e.keyCode === 13) {
      handleCreateConference();
    }
  }

  const handleChangeInput = (e) => {
    setConfName(e.target.value);
    $('.confname-input').removeClass('inputError');
  }

  //TODO Ajeitar o CSS dessa página
  return(
    <div className="container-NewQuickConference">
      <MenuV4h history={history}/>
      <div className='container-input'>
        <div className='input-container'>
          <input className='confname-input' onKeyUp={keyUpHandler} autoFocus placeholder='Digite o apelido para a conferência' onChange={handleChangeInput}/>
          <button className='submit-button' onClick={handleCreateConference}>Entrar</button>
        </div>
      </div>
    </div>
  );

};