/* global config */

import React, { useState, useEffect, useRef } from "react";
import "./Login.css";
import { UserAPI } from "../../services/index";
import ReCAPTCHA from "react-google-recaptcha";
import { message, notification } from "antd";

import logo from "../../assets/v4h/logo.svg";
import background from "../../assets/v4h/background.jpg";

require("dotenv/config");

export function Login({ history, match }) {
  const [email, setEmail] = useState();
  const [pass, setPass] = useState();
  const [attempts, setAttempts] = useState(0);
  const [value, setValue] = useState(null);
  const [load, setLoad] = useState(false);
  const reRef = useRef();

  useEffect(() => {
    if (localStorage.getItem("isAuthenticated") == "false") {
      notification.error({
        message: "Token de acesso expirado.",
        description: "efetue o login para continuar.",
      });
    }
  }, [match.params.register]);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      let response = await UserAPI.login(email, pass);
      console.log(response);

      let password = "";
      for (var i in pass) {
        password += "*";
      }

      if (load) {
        reRef.current.reset();
        setValue(null);
      }

      if (!response) {
        if ((attempts + 1) >= config.recaptcha.attempts) {
          setLoad(true);
        }

        setAttempts(attempts + 1);
        return;
      }

      response.password = password;

      localStorage.setItem("v4h_uuid", response.uuid);
      localStorage.setItem("v4h_token", response.token);
      localStorage.setItem("v4h_user_firstName", response.firstName);
      localStorage.setItem("v4h_user_lastName", response.lastName);
      localStorage.setItem("v4h_user_passwordLength", response.password);
      localStorage.setItem("v4h_user_email", response.email);
      localStorage.setItem("v4h_user_companyName", response.companyName);
      localStorage.setItem("v4h_menu", "MenuV4h");
      localStorage.setItem("v4h_settings_menu_theme", "dark");
      localStorage.setItem("isAuthenticated", true);

      if (match.params.page) {
        return history.push(`/${match.params.page}`);
      }

      history.push("/ScheduleAppointment");
    } catch {
      console.log("falha no envio dos dados.");
    }
  }

  const handleChange = (value) => {
    setValue(value);
  };

  const asyncScriptOnLoad = () => {
    //this.setState({ callback: "called!" });
    console.log("scriptLoad - reCaptcha Ref-", reRef);
  };

  function forgetPassword(e) {
    e.preventDefault();
    history.push("/forgotPassword");
  }

  function handleRegister(e) {
    e.preventDefault();
    history.push("/register/true");
  }

  return (
    <div className="main-container">
      <div className="login-container">
        <form onSubmit={handleSubmit}>
          <div className="container-img">
            <img src={logo} alt="v4h" />
          </div>
          <input
            id="input_email"
            placeholder="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoFocus
          />
          <input
            id="input_pass"
            placeholder="Senha"
            type="password"
            value={pass}
            onChange={(e) => setPass(e.target.value)}
          />
          <button type="submit" className="submit" disabled={load ? !value : false}>
            Entrar
          </button>
          <div className="forgetPassword" onClick={forgetPassword}>
            Esqueci minha senha
          </div>
          {load && (
            <ReCAPTCHA
              style={{ display: "inline-block", marginTop: "8px" }}
              theme="light"
              ref={reRef}
              sitekey={config.recaptcha.sitekey}
              onChange={handleChange}
              asyncScriptOnLoad={asyncScriptOnLoad}
            />
          )}
        </form>

        {match.path === "/cadastro" && (
          <div className="registerButtonContainer" onClick={handleRegister}>
            <svg className="registerButtonSvg">
              <rect className="registerButtonRect" />
            </svg>
            <div className="registerButtonText">Cadastre-se</div>
          </div>
        )}
      </div>
      {/* <div className="right-container">
        <img src={right} alt="pbmeet-right" />
      </div> */}
    </div>
  );
}
