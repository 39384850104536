import React, { useState } from "react";
import "./ForgetPassword.css";
import { UserAPI } from "../../services";
import { ButtonBack } from "../../compoments/common/buttonBack";
import { Spin, Modal } from "antd";
import logo from '../../assets/v4h/logo.svg';

export function ForgetPassword({ history }) {
  const [email, setEmail] = useState("");
  const [loading, setloading] = useState(false);

  const handlerSubmit = async (e) => {
    e.preventDefault();
    setloading(true);
    const { error } = await UserAPI.forgotPassword(email);
    setloading(false);

    if (error) {
      showModalError();
      return;
    }

    showModalSucess();
  };

  const showModalSucess = () => {
    Modal.success({
      content:
        "Se seu e-mail estiver cadastrado você deverá receber um e-mail nos próximos minutos.",
      onOk: () => history.push("/"),
    });
  };

  const showModalError = () => {
    Modal.error({
      content:
        "Não conseguimos achar você no nosso sistema, confira se o e-mail está correto.",
    });
  };

  return (
    <div id="containerForgetPassword">
      <ButtonBack history={history} go="/" />

      <div className="container-img">
          <img
              style={{width: '100%'}}
              src={logo}
              alt="pbmmet"
          />
      </div>

      <div className="title">Redefinição de senha</div>

      <p className="description">
        Insira o e-mail que você usou no cadastro. Nós mandaremos um e-mail
        <br />
        com seu nome de usuário e um link para você redefinir sua senha.
      </p>
      <form onSubmit={handlerSubmit}>
        <input
          placeholder="Insira seu Email"
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          autoFocus
        />
        <Spin spinning={loading}>
          <button type="submit" className="btnSubmit">
            Enviar
          </button>
        </Spin>
      </form>
    </div>
  );
}
