import React from "react";
import moment from 'moment';
import { Icon, InlineIcon } from "@iconify/react";
import exclamationCircle from "@iconify/icons-fa/exclamation-circle";
import {
  notification,
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Empty,
  Input,
  Select,
  DatePicker,
  TimePicker,
  Spin,
  Modal,
} from "antd";
import "./AddButton.css";
import { ConferenceAPI } from "../../services/";
import api from "../../services/api";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { confirm } = Modal;

export class DetailsSession extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      title: "",
      scheduleDate: null,
      collaborators: [],
      startTime: "",
      finishTime: "",
      description: "",
      loading: "",
      typeConference: "class",
      readyToSend: false,
      startTimeDate: null,
      finishTimeDate: null,
      startValidator: "",
      finishValidator: "",
    };

    this.children = [];
    this.invalidInputs = [];
    this.collaboratorsUuid = [];
    this.collaboratorsEmail = [];
    this.collaboratorsHandler = [];
  }

  async componentDidMount() {
    const response = await api.get("/companies/users", {
      headers: { Authorization: "Bearer " + localStorage.getItem("v4h_token") },
    });

    for (var x in response.data) {
      if (response.data[x].uuid === localStorage.getItem("v4h_uuid")) {
        continue;
      }
      this.children.push(
        <Option key={[response.data[x].uuid, response.data[x].email]}>
          {response.data[x].email}
        </Option>
      );
    }
  }

  componentWillReceiveProps(props) {
    const { session } = this.props;
    let users = [];

    if (session.title && this.state.updateCollaborators) {
      console.log("atualizando os usuários");
      session.users.map((user) => users.push(`${user.uuid},${user.email}`));
      this.setState({ collaborators: users, updateCollaborators: false });
    }

    this.setState({ visible: props.visible });
  }

  showDrawer = () => {
    this.setState(
      {
        visible: true,
      },
      () => {
        setTimeout(() => {
          this.testInput && this.testInput.focus();
        }, 1);
      }
    );
  };

  validateFields = () => {
    const fields = this.props.form.getFieldsValue();
    console.log(fields);

    // console.log(this.collaboratorsHandler.length);
    return (fields.title) && (fields.date) && (fields.participants.length > 0) && (fields.timestart) && (fields.timeend) && this.state.finishValidator !== "error" && this.invalidInputs.length === 0;
  }

  showDeleteConfirm = (uuid) => {
    confirm({
      title: "Você realmente deseja apagar esse evento? Os demais participantes serão notificados!",
      width: "400px",
      cancelText: "Não",
      okType: "danger",
      okText: "Sim",
      autoFocusButton: "cancel",
      onCancel() {
        console.log("Cancel");
      },
      onOk() {
        console.log("OK");
        return new Promise((resolve, reject) => {
          ConferenceAPI.deleteSchedule(uuid, resolve, reject);
        }).catch(() => console.log("Oops errors!"));
      },
    });
  };

  onClose = () => {
    this.props.close();
    this.setState({ 
      visible: false,
      title: "",
      scheduleDate: null,
      collaborators: [],
      startTime: "",
      finishTime: "",
      description: "",
      loading: "",
      typeConference: "class",
      readyToSend: false,
      startTimeDate: null,
      finishTimeDate: null,
      startValidator: "",
      finishValidator: "", 
    });
    this.props.form.resetFields();
  };

  handleTitle = (e) => {
    this.setState({
      title: e.target.value,
    });
  };

  handleTypeConference = (value) => {
    this.setState({
      typeConference: value,
    });
  };

  handleCollaborators = (value) => {
    console.log("valueee: ", value);
    this.setState({ collaborators: value });
    this.collaboratorsHandler = value;
    // setTimeout(() => console.log(this.state.collaborators), 1000);
  };

  handleDate = (e) => {

    if (!e) {
      this.setState({ scheduleDate: '' });
      return;
    }

    console.log("date: ", e);
    const year = e._d.getFullYear();
    const month = this.formatHour(e._d.getMonth() + 1);
    const day = this.formatHour(e._d.getDate());
    const hours = String(e._d).substring(16, 24);
    this.setState({
      scheduleDate: year + "-" + month + "-" + day + " " + hours,
    });
  };

  formatHour = (value) => {
    if (String(value).length == 1) {
      return "0" + value;
    }
    return value;
  };

  handleStartTime = (e) => {
    if (!e) {
      this.setState({ startTimeDate: null });
      return;
    }

    if (this.state.finishTimeDate && (e._d.getTime() > this.state.finishTimeDate.getTime())) {
      this.setState({ startTimeDate: e._d, finishValidator: "error" });
    } else {
      const temp = moment(e._d);
      this.props.form.setFieldsValue({ timeend: temp.add(1, 'hours') });
      console.log(String(e._d).substring(16, 21));
      this.setState({ 
        startTimeDate: e._d, 
        startTime: String(e._d).substring(16, 21), 
        finishTime: temp.format('HH:mm')
      });
    }
  };

  handleFinishTime = (e) => {
    console.log(e);
    if (!e) {
      this.setState({ finishTime: "", finishValidator: "error" });
      return;
    }
    
    console.log(e._d);
    console.log(this.state.startTimeDate); 

    const fields = this.props.form.getFieldsValue();
    const startTime = this.state.startTimeDate ? this.state.startTimeDate : new Date(fields.timestart);

    if (e._d.getTime() < startTime.getTime()) {
      this.setState({ finishValidator: "error" });
    } else {
      this.setState({ finishValidator: "", finishTimeDate: e._d });
      this.setState({ finishTime: String(e._d).substring(16, 21) });
    }

  };

  handleDescription = (e) => {
    this.setState({
      description: e.target.value,
    });
  };

  fixTime = (e) => {
    return String(e._d).substring(16, 21);
  };

  fixDate = (e) => {
    const year = e._d.getFullYear();
    const month = this.formatHour(e._d.getMonth() + 1);
    const day = this.formatHour(e._d.getDate());
    const hours = String(e._d).substring(16, 24);

    return year + "-" + month + "-" + day + " " + hours;
  };

  adjustParticipantData = (collaborators) => {
    //adjustment in participants.
    for (var i = 0; i < collaborators.length; i++) {

      const uuid_email = collaborators[i].split(",");

      if (uuid_email.length === 2) {
        this.collaboratorsUuid.push(uuid_email[0]);
      } else {
        this.collaboratorsEmail.push(uuid_email[0]);
      }
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    let usersToSend = [];
    this.props.session.users.map((user) =>
      usersToSend.push(`${user.uuid},${user.email}`)
    );

    if (this.collaboratorsHandler.length)
      this.adjustParticipantData(this.collaboratorsHandler);
    else this.adjustParticipantData(this.state.collaborators);

    if (!this.collaboratorsEmail.length)
      this.adjustParticipantData(usersToSend);

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
      }
      console.log(values);
      if (values.timestart.isAfter(values["timeend"])) {
        this.props.form.setFields({
          timeend: {
            value: values.timeend,
            errors: [new Error("Hora de fim menor")],
          },
        });
      }
    });

    const {
      title,
      description,
      users,
      type,
      schedule_date,
      start_time,
      finish_time,
      uuid,
    } = this.props.session;

    const dataToSend = {
      title: this.state.title || title,
      description: this.state.description || description,
      scheduleDate: this.state.scheduleDate || this.fixDate(schedule_date),
      startTime: this.state.startTime || this.fixTime(start_time),
      finishTime: this.state.finishTime || this.fixTime(finish_time),
      collaborators: this.collaboratorsUuid,
      collaboratorsEmail: this.collaboratorsEmail,
      type: this.state.typeConference || type,
    };

    try {
      await ConferenceAPI.updateConference(uuid, dataToSend);
      this.onClose();
      this.setState({ loading: false });
      this.openNotificationWithIcon(
        "success",
        "Sucesso.",
        "Agendamendo atualizado com Sucesso."
      );
      setTimeout(() => {
        window.location.reload(false);
      }, 500);
    } catch (error) {
      this.setState({ loading: false });
      this.openNotificationWithIcon(
        "error",
        "Falha ao atualizar o evento agendado.",
        "Houve um problema ao processar a atualização, tente novamente."
      );
    }
  };

  emailValidator = (rule, values, callback) => {
    // this.onValuesChange();
    const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    this.invalidInputs = [];
    console.log(this.invalidInputs);

    values.map((value) => {
      let arr = value.split(',');

      console.log(arr.length === 2 && !emailRegex.test(arr[1]));
      if(arr.length === 2 && !emailRegex.test(arr[1])) {
        console.log('User: ', arr[1])
        this.invalidInputs.push(arr[1]);
      }

      console.log(arr.length === 1 && !emailRegex.test(value));
      if (arr.length === 1 && !emailRegex.test(value)) {
        console.log('Standalone: ', value)
        this.invalidInputs.push(value);
      }

    });

    console.log('INVALID INPUTS');
    console.log(this.invalidInputs);

    if(this.invalidInputs.length === 0){
        callback()
    } else {
      callback('Existem e-mails não válidos');
    }
  }

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      title,
      description,
      users,
      type,
      schedule_date,
      start_time,
      finish_time,
      uuid,
      collaborators_email,
    } = this.props.session;

    console.log(collaborators_email);

    console.log("session: ", this.props);

    let userSelected = [];
    users && users.map((user) => userSelected.push(`${user.uuid},${user.email}`));

    if (collaborators_email) {
      userSelected = userSelected.concat(collaborators_email);
    }

    console.log(this.props.session);

    return (
      <div className="addDropdown">
        <Drawer
          title="Detalhes da Conferência"
          width={720}
          onClose={this.onClose}
          visible={this.state.visible}
          maskClosable={false}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Spin spinning={this.state.loading}>
            <Form layout="vertical">
              <Row gutter={16}>
                <Col span={17}>
                  <Form.Item label="Título">
                    {getFieldDecorator("title", {
                      rules: [
                        {
                          required: true,
                          message: "Por favor, adicione o título",
                        },
                      ],
                      initialValue: title,
                    })(
                      <Input
                        ref={(input) => {
                          this.testInput = input;
                        }}
                        placeholder="Adicione o título"
                        onChange={this.handleTitle}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item label="Tipo da Sessão">
                    <Select
                      defaultValue={type}
                      onChange={this.handleTypeConference}
                    >
                      <Option value="conference">Conferência</Option>
                      <Option value="class">Aula</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Participantes">
                    {getFieldDecorator("participants", {
                      rules: [
                        {
                          required: true,
                          message: "Por favor, selecione os Participantes",
                        },
                      ],
                      initialValue: userSelected,
                    })(
                      <Select
                        notFoundContent={<Empty description={"Sem Dados"} />}
                        mode="tags"
                        style={{ width: "100%" }}
                        placeholder="Selecione os Participantes"
                        onChange={this.handleCollaborators}
                      >
                        {this.children}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Data">
                    {getFieldDecorator("date", {
                      rules: [
                        {
                          required: true,
                          message: "Por favor, escolha a data",
                        },
                      ],
                      initialValue: schedule_date,
                    })(
                      <DatePicker
                        size={"default"}
                        placeholder="Selecione a Data"
                        onChange={this.handleDate}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Hora">
                    {getFieldDecorator("timestart", {
                      rules: [
                        {
                          required: true,
                          message: "Por favor, escolha o horário de início",
                        },
                      ],
                      initialValue: start_time,
                    })(
                      <TimePicker
                        format={"HH:mm"}
                        placeholder="Início"
                        onChange={this.handleStartTime}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item validateStatus={this.state.finishValidator}>
                    {getFieldDecorator("timeend", {
                      rules: [
                        {
                          required: true,
                          message: "Por favor, escolha o horário de término",
                        },
                      ],
                      initialValue: finish_time,
                    })(
                      <TimePicker
                        format={"HH:mm"}
                        style={{ marginTop: "28px" }}
                        placeholder="Fim"
                        onChange={this.handleFinishTime}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Descrição">
                    {getFieldDecorator("description", {
                      initialValue: description,
                    })(
                      <Input.TextArea
                        rows={4}
                        placeholder="Adicione uma descrição"
                        onChange={this.handleDescription}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#fff",
              textAlign: "right",
            }}
          >
            <div>
              <Button
                onClick={() => this.showDeleteConfirm(uuid)}
                type="danger"
              >
                Apagar
              </Button>
            </div>
            <div>
              <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                Cancelar
              </Button>
              <Button
                onClick={this.handleSubmit}
                htmlType="submit"
                type="submit"
                disabled={!this.validateFields()}
              >
                Salvar
              </Button>
            </div>
          </div>
        </Drawer>
      </div>
    );
  }
}
