import api from "./api";
import { message } from "antd";

class UserAPI {
  /**
   * Connect to the server with the supplied credentials.
   *
   * @param {string} email
   * @param {string} password
   *
   * @returns {Object}
   */
  login = async (email, password) => {
    try {
      let response = await api.post(
        "/sessions",
        {
          email: email,
          password: password,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      return response.data;
    } catch (error) {
      message.error("Email ou senha inválida.");
      document.getElementById("input_email").focus();
    }
  };

  /**
   * update user profile.
   *
   * @param {string} email
   * @param {string} password
   *
   * @returns {Object}
   */
  updateProfile = async (uuid, token, firstName, lastName, email) => {
    try {
      const response = await api.put(
        `/users/update/${uuid}`,
        {
          uuid: uuid,
          firstName: firstName,
          lastName: lastName,
          email: email,
        },
        { headers: { Authorization: "Bearer " + token } }
      );

      return response.data;
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.setItem("isAuthenticated", false);
      }

      message.error("Não foi possível atualizar o seu perfil.");
      throw "Error";
    }
  };

  /**
   * delete user.
   *
   * @param {string} email
   * @param {string} password
   *
   * @returns {Object}
   */
  delete = async (uuid, token) => {
    try {
      const response = await api.delete(`/users/${uuid}`, {
        headers: { Authorization: "Bearer " + token },
      });

      return { error: false };
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.setItem("isAuthenticated", false);
      }

      message.error("Não foi possível apagar sua conta.");
      return { error: true };
    }
  };

  /**
   * send a message to your email to change your password.
   *
   * @param {String} email
   */
  forgotPassword = async (email) => {
    try {
      const response = await api.post(
        "/users/recoverPassword",
        {
          email: email,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      return { error: false };
    } catch (err) {
      return { error: true };
    }
  };
}

export default new UserAPI();
