import React from "react";
import "./MyAccountConfig.css";
import { Table, Empty, Icon, message } from "antd";
import { MyAccountConfigAvatar } from "./index";
import { ConfigModal } from "../menuAccountConfig";
import $ from "jquery";
import { UserAPI } from "../../services";

export class MyAccountConfig extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      typeInput: "",
      loading: false,
      visible: false,
      first_name: localStorage.getItem("v4h_user_firstName"),
      last_name: localStorage.getItem("v4h_user_lastName"),
      email: localStorage.getItem("v4h_user_email"),
      company: localStorage.getItem("v4h_user_companyName"),
      token: localStorage.getItem("v4h_token"),
      uuid: localStorage.getItem("v4h_uuid"),
      password: localStorage.getItem("v4h_user_passwordLength"),
    };

    this.columns = [
      {
        title: (
          <div className="personInfoTitle">
            <span className="personInfoTitlePerfil">Perfil</span>{" "}
            <p>
              Algumas informações podem estar visíveis para outras pessoas que
              estejam usando os serviços do V4H.
            </p>{" "}
          </div>
        ),
        dataIndex: "personInfoTitle",
        key: "personInfoTitle",
        className: "margin",
        colSpan: 24,
      },
      {
        dataIndex: "personInfoData",
        key: "personInfoData",
        className: "margin",
        colSpan: 0,
      },
      {
        dataIndex: "personInfoIcon",
        key: "personInfoIcon",
        className: "margin personInfoIcon",
        colSpan: 0,
      },
    ];

    this.data = [
      // ## future version ##
      // {
      //   personInfoTitle: "Foto",
      //   personInfoData: "Uma foto ajuda a personalizar sua conta",
      //   personInfoIcon: <MyAccountConfigAvatar />,
      // },
      {
        personInfoTitle: "Nome",
        personInfoData: this.state.first_name + " " + this.state.last_name,
        personInfoIcon: <Icon type="right" />,
      },
      {
        personInfoTitle: "Email",
        personInfoData: this.state.email,
        personInfoIcon: <Icon type="right" />,
      },
      {
        personInfoTitle: "Organização",
        personInfoData: this.state.company,
        personInfoIcon: <Icon type="right" />,
      },
      {
        personInfoTitle: "Alterar Senha",
        personInfoData: (
          <input
            className="password"
            type="password"
            value={this.state.password}
            disabled
          />
        ),
        personInfoIcon: <Icon type="right" />,
      },
      {
        personInfoTitle: "Excluir Conta",
        personInfoData:
          "Você pode excluir permanentemente sua Conta do V4H e todos os seus dados serão perdidos.",
        personInfoIcon: <Icon type="right" />,
      },
    ];
  }

  handleRowEvent(rowIndex) {
    switch (rowIndex) {
      case 0:
        this.setState({ title: "Nome", typeInput: "text" });
        this.showModal();
        setTimeout(() => $("#inputConfigModal_nome").focus(), 100);
        break;
      case 1:
        this.setState({ title: "Email", typeInput: "text" });
        this.showModal();
        setTimeout(() => $("#inputConfigModal_email").focus(), 100);
        break;
      case 3:
        this.setState({ title: "Senha", typeInput: "password" });
        this.props.history.push("/signin");
        break;
      case 4:
        this.setState({ title: "Apagar conta V4H?" });
        this.showModal();
        break;
      default:
        return;
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  updateValues = (data) => {
    localStorage.setItem("v4h_user_firstName", data.firstName);
    localStorage.setItem("v4h_user_lastName", data.lastName);
    localStorage.setItem("v4h_user_email", data.email);

    document.location.reload(false);
  };

  handleOk = async (data) => {
    this.setState({ loading: true });
    try {
      const response = await UserAPI.updateProfile(
        this.state.uuid,
        this.state.token,
        data.firstName,
        data.lastName,
        data.email
      );

      if (response.error) {
        return message.error("Email já está em uso.");
      }

      this.updateValues(response);
      this.setState({ loading: false, visible: false });
    } catch {
      console.log("error");
      $("#inputConfigModal_email").addClass("form-error");
      $(".error-msg").addClass("error-msg-visible");
      $("#inputConfigModal_email").focus();
      this.setState({ loading: false });
      return;
    }
  };

  handleCancel = () => {
    if (this.state.title == "Email") {
      $("#inputConfigModal_email").removeClass("form-error");
      $(".error-msg").removeClass("error-msg-visible");
    }
    this.setState({ visible: false });
  };

  render() {
    return (
      <div id="myAccountConfigContainer">
        <ConfigModal
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          title={this.state.title}
          onOk={this.handleOk.bind(this)}
          onCancel={this.handleCancel.bind(this)}
          loading={this.state.loading}
          history={this.props.history}
        />

        <div className="header">
          <h1>Informações Pessoais</h1>
        </div>
        <div className="body">
          <Table
            locale={{ emptyText: <Empty description={"Sem Dados"} /> }}
            columns={this.columns}
            dataSource={this.data}
            size="small"
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  this.handleRowEvent(rowIndex);
                }, // click row
              };
            }}
          />
        </div>
      </div>
    );
  }
}
